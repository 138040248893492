import React from "react";

const flapmaxlogo = require("../images/faviconflapmax.png");

const Footer = () => {
  return (
    <footer className="footer footer-sm">
      <div className="d-flex justify-content-between align-items-center flex-md-row flex-column px-md-4">
        <div>
          <img height={45} src={flapmaxlogo} className="rounded-circle" />
        </div>
        <p className="mb-0 mt-3 mt-md-0">© Flapmax 2021 All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
